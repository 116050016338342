@import '../../../../styles/settings.colors';
@import '../../../../styles/settings.font-size';
@import '../../../../styles/settings.media-queries';
@import '../../../../styles/settings.shadows';
@import '../../../../styles/settings.animation';
.letterK {
  display: inline;
  color: var(--kp-color-brand-monogram-red);
  font-size: inherit;
  font-weight: bold;
}

.letterP {
  display: inline;
  color: var(--kp-color-brand-monogram-navy-blue);
  font-size: inherit;
  font-weight: bold;
}
