@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.container {
  margin-left: auto;
  margin-right: auto;
  width: 1024px;
  padding: 0 0.8rem;
  @include respond-below(sm) {
    padding: 0 1.6rem;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  min-width: 1024px; //change to 100% for responsive
  @include respond-below(sm) {
    min-width: 100%;
  }
}

.row {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  // flex-wrap: wrap;

  > * {
    margin-right: 1.6rem;
  }

  > :last-child {
    margin-right: 0;
  }
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

@media only screen and (max-width: 576px) {
  .container {
    width: 100%;
  }

  .col-xs,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-13,
  .col-xs-14,
  .col-xs-15,
  .col-xs-16 {
    flex: 0 0 auto;
  }

  .col-xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xs-1 {
    flex-basis: calc(6.25% - 15px);
    max-width: calc(6.25% - 15px);
  }

  .col-xs-2 {
    flex-basis: calc(12.5% - 14px);
    max-width: calc(12.5% - 14px);
  }

  .col-xs-3 {
    flex-basis: calc(18.75% - 13px);
    max-width: calc(18.75% - 13px);
  }

  .col-xs-4 {
    flex-basis: calc(25% - 12px);
    max-width: calc(25% - 12px);
  }

  .col-xs-5 {
    flex-basis: calc(31.25% - 11px);
    max-width: calc(31.25% - 11px);
  }

  .col-xs-6 {
    flex-basis: calc(37.5% - 10px);
    max-width: calc(37.5% - 10px);
  }

  .col-xs-7 {
    flex-basis: calc(43.75% - 9px);
    max-width: calc(43.75% - 9px);
  }

  .col-xs-8 {
    flex-basis: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }

  .col-xs-9 {
    flex-basis: calc(56.25% - 7px);
    max-width: calc(56.25% - 7px);
  }

  .col-xs-10 {
    flex-basis: calc(62.5% - 6px);
    max-width: calc(62.5% - 6px);
  }

  .col-xs-11 {
    flex-basis: calc(68.75% - 5px);
    max-width: calc(68.75% - 5px);
  }

  .col-xs-12 {
    flex-basis: calc(75% - 4px);
    max-width: calc(75% - 4px);
  }

  .col-xs-13 {
    flex-basis: calc(81.25% - 3px);
    max-width: calc(81.25% - 3px);
  }

  .col-xs-14 {
    flex-basis: calc(87.5% - 2px);
    max-width: calc(87.5% - 2px);
  }

  .col-xs-15 {
    flex-basis: calc(93.75% - 1px);
    max-width: calc(93.75% - 1px);
  }

  .col-xs-16 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-xs-offset-1 {
    margin-left: calc(6.25% + 1px);
  }

  .col-xs-offset-2 {
    margin-left: calc(12.5% + 2px);
  }

  .col-xs-offset-3 {
    margin-left: calc(18.75% + 3px);
  }

  .col-xs-offset-4 {
    margin-left: calc(25% + 4px);
  }

  .col-xs-offset-5 {
    margin-left: calc(31.25% + 5px);
  }

  .col-xs-offset-6 {
    margin-left: calc(37.5% + 6px);
  }

  .col-xs-offset-7 {
    margin-left: calc(43.75% + 7px);
  }

  .col-xs-offset-8 {
    margin-left: calc(50% + 8px);
  }

  .col-xs-offset-9 {
    margin-left: calc(56.25% + 9px);
  }

  .col-xs-offset-10 {
    margin-left: calc(62.5% + 10px);
  }

  .col-xs-offset-11 {
    margin-left: calc(68.75% + 11px);
  }

  .col-xs-offset-12 {
    margin-left: calc(75% + 12px);
  }

  .col-xs-offset-13 {
    margin-left: calc(81.25% + 13px);
  }

  .col-xs-offset-14 {
    margin-left: calc(87.5% + 14px);
  }

  .col-xs-offset-15 {
    margin-left: calc(93.75% + 15px);
  }

  .start-xs {
    justify-content: flex-start;
    text-align: start;
  }

  .center-xs {
    justify-content: center;
    text-align: center;
  }

  .end-xs {
    justify-content: flex-end;
    text-align: end;
  }

  .top-xs {
    align-items: flex-start;
  }

  .middle-xs {
    align-items: center;
  }

  .bottom-xs {
    align-items: flex-end;
  }

  .around-xs {
    justify-content: space-around;
  }

  .between-xs {
    justify-content: space-between;
  }

  .first-xs {
    order: -1;
  }

  .last-xs {
    order: 1;
  }
}
@media only screen and (max-width: 1024px) {
  .container {
    width: 100%;
  }
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-13,
  .col-sm-14,
  .col-sm-15,
  .col-sm-16 {
    flex: 0 0 auto;
  }

  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    flex-basis: calc(6.25% - 15px);
    max-width: calc(6.25% - 15px);
  }

  .col-sm-2 {
    flex-basis: calc(12.5% - 14px);
    max-width: calc(12.5% - 14px);
  }

  .col-sm-3 {
    flex-basis: calc(18.75% - 13px);
    max-width: calc(18.75% - 13px);
  }

  .col-sm-4 {
    flex-basis: calc(25% - 12px);
    max-width: calc(25% - 12px);
  }

  .col-sm-5 {
    flex-basis: calc(31.25% - 11px);
    max-width: calc(31.25% - 11px);
  }

  .col-sm-6 {
    flex-basis: calc(37.5% - 10px);
    max-width: calc(37.5% - 10px);
  }

  .col-sm-7 {
    flex-basis: calc(43.75% - 9px);
    max-width: calc(43.75% - 9px);
  }

  .col-sm-8 {
    flex-basis: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }

  .col-sm-9 {
    flex-basis: calc(56.25% - 7px);
    max-width: calc(56.25% - 7px);
  }

  .col-sm-10 {
    flex-basis: calc(62.5% - 6px);
    max-width: calc(62.5% - 6px);
  }

  .col-sm-11 {
    flex-basis: calc(68.75% - 5px);
    max-width: calc(68.75% - 5px);
  }

  .col-sm-12 {
    flex-basis: calc(75% - 4px);
    max-width: calc(75% - 4px);
  }

  .col-sm-13 {
    flex-basis: calc(81.25% - 3px);
    max-width: calc(81.25% - 3px);
  }

  .col-sm-14 {
    flex-basis: calc(87.5% - 2px);
    max-width: calc(87.5% - 2px);
  }

  .col-sm-15 {
    flex-basis: calc(93.75% - 1px);
    max-width: calc(93.75% - 1px);
  }

  .col-sm-16 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-1 {
    margin-left: calc(6.25% + 1px);
  }

  .col-sm-offset-2 {
    margin-left: calc(12.5% + 2px);
  }

  .col-sm-offset-3 {
    margin-left: calc(18.75% + 3px);
  }

  .col-sm-offset-4 {
    margin-left: calc(25% + 4px);
  }

  .col-sm-offset-5 {
    margin-left: calc(31.25% + 5px);
  }

  .col-sm-offset-6 {
    margin-left: calc(37.5% + 6px);
  }

  .col-sm-offset-7 {
    margin-left: calc(43.75% + 7px);
  }

  .col-sm-offset-8 {
    margin-left: calc(50% + 8px);
  }

  .col-sm-offset-9 {
    margin-left: calc(56.25% + 9px);
  }

  .col-sm-offset-10 {
    margin-left: calc(62.5% + 10px);
  }

  .col-sm-offset-11 {
    margin-left: calc(68.75% + 11px);
  }

  .col-sm-offset-12 {
    margin-left: calc(75% + 12px);
  }

  .col-sm-offset-13 {
    margin-left: calc(81.25% + 13px);
  }

  .col-sm-offset-14 {
    margin-left: calc(87.5% + 14px);
  }

  .col-sm-offset-15 {
    margin-left: calc(93.75% + 15px);
  }

  .start-sm {
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@media only screen and (min-width: 992px) {
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-13,
  .col-md-14,
  .col-md-15,
  .col-md-16 {
    flex: 0 0 auto;
  }

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    flex-basis: calc(6.25% - 15px);
    max-width: calc(6.25% - 15px);
  }

  .col-md-2 {
    flex-basis: calc(12.5% - 14px);
    max-width: calc(12.5% - 14px);
  }

  .col-md-3 {
    flex-basis: calc(18.75% - 13px);
    max-width: calc(18.75% - 13px);
  }

  .col-md-4 {
    flex-basis: calc(25% - 12px);
    max-width: calc(25% - 12px);
  }

  .col-md-5 {
    flex-basis: calc(31.25% - 11px);
    max-width: calc(31.25% - 11px);
  }

  .col-md-6 {
    flex-basis: calc(37.5% - 10px);
    max-width: calc(37.5% - 10px);
  }

  .col-md-7 {
    flex-basis: calc(43.75% - 9px);
    max-width: calc(43.75% - 9px);
  }

  .col-md-8 {
    flex-basis: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }

  .col-md-9 {
    flex-basis: calc(56.25% - 7px);
    max-width: calc(56.25% - 7px);
  }

  .col-md-10 {
    flex-basis: calc(62.5% - 6px);
    max-width: calc(62.5% - 6px);
  }

  .col-md-11 {
    flex-basis: calc(68.75% - 5px);
    max-width: calc(68.75% - 5px);
  }

  .col-md-12 {
    flex-basis: calc(75% - 4px);
    max-width: calc(75% - 4px);
  }

  .col-md-13 {
    flex-basis: calc(81.25% - 3px);
    max-width: calc(81.25% - 3px);
  }

  .col-md-14 {
    flex-basis: calc(87.5% - 2px);
    max-width: calc(87.5% - 2px);
  }

  .col-md-15 {
    flex-basis: calc(93.75% - 1px);
    max-width: calc(93.75% - 1px);
  }

  .col-md-16 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-1 {
    flex-basis: calc(6.25% - 15px);
    max-width: calc(6.25% - 15px);
  }

  .col-lg-2 {
    flex-basis: calc(12.5% - 14px);
    max-width: calc(12.5% - 14px);
  }

  .col-lg-3 {
    flex-basis: calc(18.75% - 13px);
    max-width: calc(18.75% - 13px);
  }

  .col-lg-4 {
    flex-basis: calc(25% - 12px);
    max-width: calc(25% - 12px);
  }

  .col-lg-5 {
    flex-basis: calc(31.25% - 11px);
    max-width: calc(31.25% - 11px);
  }

  .col-lg-6 {
    flex-basis: calc(37.5% - 10px);
    max-width: calc(37.5% - 10px);
  }

  .col-lg-7 {
    flex-basis: calc(43.75% - 9px);
    max-width: calc(43.75% - 9px);
  }

  .col-lg-8 {
    flex-basis: calc(50% - 8px);
    max-width: calc(50% - 8px);
  }

  .col-lg-9 {
    flex-basis: calc(56.25% - 7px);
    max-width: calc(56.25% - 7px);
  }

  .col-lg-10 {
    flex-basis: calc(62.5% - 6px);
    max-width: calc(62.5% - 6px);
  }

  .col-lg-11 {
    flex-basis: calc(68.75% - 5px);
    max-width: calc(68.75% - 5px);
  }

  .col-lg-12 {
    flex-basis: calc(75% - 4px);
    max-width: calc(75% - 4px);
  }

  .col-lg-13 {
    flex-basis: calc(81.25% - 3px);
    max-width: calc(81.25% - 3px);
  }

  .col-lg-14 {
    flex-basis: calc(87.5% - 2px);
    max-width: calc(87.5% - 2px);
  }

  .col-lg-15 {
    flex-basis: calc(93.75% - 1px);
    max-width: calc(93.75% - 1px);
  }

  .col-lg-16 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-1 {
    margin-left: calc(6.25% + 1px);
  }

  .col-md-offset-2 {
    margin-left: calc(12.5% + 2px);
  }

  .col-md-offset-3 {
    margin-left: calc(18.75% + 3px);
  }

  .col-md-offset-4 {
    margin-left: calc(25% + 4px);
  }

  .col-md-offset-5 {
    margin-left: calc(31.25% + 5px);
  }

  .col-md-offset-6 {
    margin-left: calc(37.5% + 6px);
  }

  .col-md-offset-7 {
    margin-left: calc(43.75% + 7px);
  }

  .col-md-offset-8 {
    margin-left: calc(50% + 8px);
  }

  .col-md-offset-9 {
    margin-left: calc(56.25% + 9px);
  }

  .col-md-offset-10 {
    margin-left: calc(62.5% + 10px);
  }

  .col-md-offset-11 {
    margin-left: calc(68.75% + 11px);
  }

  .col-md-offset-12 {
    margin-left: calc(75% + 12px);
  }

  .col-md-offset-13 {
    margin-left: calc(81.25% + 13px);
  }

  .col-md-offset-14 {
    margin-left: calc(87.5% + 14px);
  }

  .col-md-offset-15 {
    margin-left: calc(93.75% + 15px);
  }

  .start-md {
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    justify-content: center;
    text-align: center;
  }

  .end-md {
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .bottom-md {
    align-items: flex-end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}
// @media only screen and (min-width: 1024px) { // uncomment for responsive
// .container {
//   width: 1024px;
// }
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-13,
.col-lg-14,
.col-lg-15,
.col-lg-16 {
  flex: 0 0 auto;
}

.col-lg {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-lg-1 {
  flex-basis: calc(6.25% - 15px);
  max-width: calc(6.25% - 15px);
}

.col-lg-2 {
  flex-basis: calc(12.5% - 14px);
  max-width: calc(12.5% - 14px);
}

.col-lg-3 {
  flex-basis: calc(18.75% - 13px);
  max-width: calc(18.75% - 13px);
}

.col-lg-4 {
  flex-basis: calc(25% - 12px);
  max-width: calc(25% - 12px);
}

.col-lg-5 {
  flex-basis: calc(31.25% - 11px);
  max-width: calc(31.25% - 11px);
}

.col-lg-6 {
  flex-basis: calc(37.5% - 10px);
  max-width: calc(37.5% - 10px);
}

.col-lg-7 {
  flex-basis: calc(43.75% - 9px);
  max-width: calc(43.75% - 9px);
}

.col-lg-8 {
  flex-basis: calc(50% - 8px);
  max-width: calc(50% - 8px);
}

.col-lg-9 {
  flex-basis: calc(56.25% - 7px);
  max-width: calc(56.25% - 7px);
}

.col-lg-10 {
  flex-basis: calc(62.5% - 6px);
  max-width: calc(62.5% - 6px);
}

.col-lg-11 {
  flex-basis: calc(68.75% - 5px);
  max-width: calc(68.75% - 5px);
}

.col-lg-12 {
  flex-basis: calc(75% - 4px);
  max-width: calc(75% - 4px);
}

.col-lg-13 {
  flex-basis: calc(81.25% - 3px);
  max-width: calc(81.25% - 3px);
}

.col-lg-14 {
  flex-basis: calc(87.5% - 2px);
  max-width: calc(87.5% - 2px);
}

.col-lg-15 {
  flex-basis: calc(93.75% - 1px);
  max-width: calc(93.75% - 1px);
}

.col-lg-16 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-lg-offset-1 {
  margin-left: calc(6.25% + 1px);
}

.col-lg-offset-2 {
  margin-left: calc(12.5% + 2px);
}

.col-lg-offset-3 {
  margin-left: calc(18.75% + 3px);
}

.col-lg-offset-4 {
  margin-left: calc(25% + 4px);
}

.col-lg-offset-5 {
  margin-left: calc(31.25% + 5px);
}

.col-lg-offset-6 {
  margin-left: calc(37.5% + 6px);
}

.col-lg-offset-7 {
  margin-left: calc(43.75% + 7px);
}

.col-lg-offset-8 {
  margin-left: calc(50% + 8px);
}

.col-lg-offset-9 {
  margin-left: calc(56.25% + 9px);
}

.col-lg-offset-10 {
  margin-left: calc(62.5% + 10px);
}

.col-lg-offset-11 {
  margin-left: calc(68.75% + 11px);
}

.col-lg-offset-12 {
  margin-left: calc(75% + 12px);
}

.col-lg-offset-13 {
  margin-left: calc(81.25% + 13px);
}

.col-lg-offset-14 {
  margin-left: calc(87.5% + 14px);
}

.col-lg-offset-15 {
  margin-left: calc(93.75% + 15px);
}

.start-lg {
  justify-content: flex-start;
  text-align: start;
}

.center-lg {
  justify-content: center;
  text-align: center;
}

.end-lg {
  justify-content: flex-end;
  text-align: end;
}

.top-lg {
  align-items: flex-start;
}

.middle-lg {
  align-items: center;
}

.bottom-lg {
  align-items: flex-end;
}

.around-lg {
  justify-content: space-around;
}

.between-lg {
  justify-content: space-between;
}

.first-lg {
  order: -1;
}

.last-lg {
  order: 1;
}
// }

.verticalGutter8 {
  margin-bottom: 0.8rem;
}

.verticalGutter16 {
  margin-bottom: 1.6rem;
}
