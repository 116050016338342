@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.addNewEdButton {
  max-width: 13.9rem;
  font-weight: bold;
  color: var(--kp-color-content-onFill) !important;

  @include respond-below(sm) {
    max-width: unset;
  }
}
