@import '../../../../styles/settings.colors';
@import '../../../../styles/settings.font-size';
@import '../../../../styles/settings.media-queries';
@import '../../../../styles/settings.shadows';
@import '../../../../styles/settings.animation';
.labelHolder {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.6rem;
  border-radius: 0.1rem;
  background-color: var(--kp-color-fill-accent);
  margin-left: 1.6rem;
  cursor: pointer;

  &::before {
    position: absolute;
    top: 0;
    right: 100%;
    content: '';
    width: 0;
    height: 0;
    border-top: 1.1rem solid transparent;
    border-bottom: 1.1rem solid transparent;
    border-right: 0.7rem solid var(--kp-color-fill-accent);
    border-radius: 0.1rem;
  }
}
