@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.primaryRed {
  padding: 0.8rem 1.6rem;
  border-radius: 0.2rem;
  background-color: var(--kp-color-fill-alert);
  color: var(--kp-color-content-onFill);
  box-shadow: 0px 2px 4px rgba(186, 0, 0, 0.3);

  @include respond-below(sm) {
    font-weight: bold;
    border-radius: 0.4rem;
  }
}

.primaryRed:visited,
.primaryRed:link {
  color: var(--kp-color-content-onFill);
}

//display default hover state
.primaryRed:hover {
  background-color: var(--kp-color-fill-alert-hover);
}

.primaryRed:disabled,
.primaryRed.isDisabled {
  background-color: var(--kp-color-fill-disabled);
  color: var(--kp-color-disabled-dim);
  box-shadow: none;
}
