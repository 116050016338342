@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6.8rem;
  background-color: var(--kp-color-bg-primary);
  color: var(--kp-color-content-regular-primary);
  position: relative;

  @include respond-between(sm, md) {
    width: 100%;
  }

  &.isOpen,
  &.kpBorder {
    box-shadow: none;
    margin-bottom: 0.3rem;
  }

  &.cars {
    background: linear-gradient(
      to right,
      $color-primary-sky-blue,
      $color-primary-sky-blue 50%,
      $color-primary-white 50%
    );
  }
  .headerInner {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &.hasShadow {
    box-shadow: inset 0px -1px 0px var(--kp-color-border-default);
    @include respond-below(sm) {
      z-index: 1;
      box-shadow: 0px 2px 2px var(--kp-color-border-default);
    }
  }

  @include respond-below(sm) {
    width: 100%;
    height: 5.7rem;
    border-top: 1px solid var(--kp-color-border-default);
  }

  &.isFixed {
    position: fixed;
    z-index: 7;
  }
}

.headerColLeft {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 19.2rem;
  min-width: 19.2rem;

  @include respond-below(sm) {
    width: max-content;
    min-width: max-content;
    button {
      padding-right: 2rem;
    }
  }
}

.headerColMiddle {
  max-width: 62.4rem;
  width: 100%;
}

.headerColRight {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  width: 19.2rem;
  min-width: 19.2rem;

  @include respond-below(sm) {
    width: max-content;
    min-width: max-content;
  }
}

.logoHolder {
  display: flex;
  justify-content: flex-start;
  padding-right: 1.6rem;
}

.newAdsHolder {
  margin-left: 1.6rem;
  font-size: $font-size-x-small;
  align-self: center;

  &.mode {
    font-size: $font-size-regular;
    display: flex;
    align-items: center;
  }
}

.icon {
  align-self: flex-start;
  margin-right: 0.8rem;
}

.bold {
  font-weight: bold;
}

.headerLoggedIn {
  display: flex;
  justify-content: center;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 0.8rem;
  }
}

.kpBorder::after {
  background: linear-gradient(
    to right,
    var(--kp-color-brand-fill-red) 25%,
    var(--kp-color-brand-fill-yellow) 25%,
    var(--kp-color-brand-fill-yellow) 50%,
    var(--kp-color-brand-fill-green) 50%,
    var(--kp-color-brand-fill-green) 75%,
    var(--kp-color-brand-fill-blue) 75%
  );
  position: absolute;
  content: '';
  height: 3px;
  right: 0px;
  left: 0px;
  bottom: -3px;
}
