@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.footer {
  width: 100%;
  background-color: var(--kp-color-bg-primary);
  flex-grow: 0;
  padding: 2.4rem 0 3.2rem 0;
  margin-top: 3.2rem;
  border-top: 0.1rem solid var(--kp-color-border-default);
  @include respond-below(sm) {
    padding: 1.6rem 0 1.6rem 0;
  }
}

.footerInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.7rem;

  .info {
    display: flex;
    align-items: center;
    color: var(--kp-color-content-regular-tertiary);
  }

  .count {
    margin-left: 2.5rem;
  }

  .footerSocialLogoHolder {
    display: flex;

    > * {
      margin-left: 0.8rem;
      font-size: 0;
    }
  }
}

.infoHolder {
  margin-right: 5.1rem !important;
}

.footerBottom {
  margin-top: 0.8rem;
  text-align: center;
  color: var(--kp-color-content-regular-secondary);
  @include respond-above(sm) {
    p {
      display: inline;
    }
  }
}

.marginBottom {
  margin-bottom: 0.8rem;
}

.appStoreInfo {
  display: flex;
  justify-content: center;
  margin: 3.6rem 0 3.6rem 0;

  > * {
    margin-left: 1.7rem;
  }
}

.mobileVersionHolder {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 0.3rem;
  }
}

.prepaidInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 6.5rem;

  .cardIcon {
    margin-top: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
      margin-left: 1.6rem;
    }
  }
}
