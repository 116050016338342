@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.height {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.content {
  flex: 1 0 auto;
  overflow: auto;
  display: flex;
}

.container {
  width: 100%;
  margin: 0 auto;
}
