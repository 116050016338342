@import '../../../styles/settings.colors';
@import '../../../styles/settings.font-size';
@import '../../../styles/settings.media-queries';
@import '../../../styles/settings.shadows';
@import '../../../styles/settings.animation';
.title {
  font-size: $font-size-x-large;
}

.list {
  > * {
    display: flex;
    line-height: 2rem;
  }
  :not(:last-child) {
    margin: 0.8rem 0;
  }
}
